/** @jsx jsx */
import { jsx } from "theme-ui";

import Layout from "../components/PageLayout";
import useSections from "../hooks/use-sections";
import Section from "../components/Section";

const SectionPage = () => {
  const pages = useSections().sort(
    ({ order: first }, { order: second }) => first - second
  );
  return (
    <Layout>
      {pages.map(({ id, title, body }, index) => {
        if (index !== 0) return;
        return (
          <Section key={id} id={id} title={title} body={body} index={index} />
        );
      })}
    </Layout>
  );
};

export default SectionPage;
