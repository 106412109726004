/** @jsx jsx */
import { jsx } from "theme-ui";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { formatToPath } from "../utils/string-format";
import { headerHeight } from "./Header";
import { footerHeight } from "./PageFooter";

const Section = ({ index, title, body }) => {
  //const topOffset = index === 0 ? [headerHeight, headerHeight] : 0;
  const topOffset = `${headerHeight}px`;

  return (
    <section
      id={formatToPath(title)}
      sx={{
        //height: ["auto", `calc(100vh - ${headerHeight}px)`],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        pt: topOffset,
        minHeight: "375px",
      }}
    >
      {title !== "Introduction" && (
        <h1 sx={{ variant: "h1.primary" }}>{title}</h1>
      )}
      <MDXRenderer>{body}</MDXRenderer>
    </section>
  );
};
export default Section;
